import React, { useEffect } from 'react';
import HeroSection from '../../components/organism/heroSection';
import QuoteCards from '../../components/organism/quoteCards';
import GetInTouch from '../../components/organism/getInTouch';
import ProjectCard from '../../components/organism/projectCard';
import { ScrollTo } from '../../hooks/scrollTo';
import './index.css'
import { getWindowWidth } from '../../hooks/getWindowWidth';
import ReactGA from "react-ga4";


const Work : React.FC = () => { 
  ScrollTo({behavior: 'instant'})

  const {windowWidth} = getWindowWidth()

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/work", title: 'My Work' });
  },[]) 


  return (
    <div className='container'>
     <HeroSection 
      title='My work' 
      text={[
        `Ever tried navigating a website and felt like you were stuck in a maze? Yeah, I hate that too. That's why I'm here to craft experiences that are not just intuitive but delightful. I design with empathy, ensuring your users feel like they're on a first-class flight rather than a bumpy bus ride.\nCheck out my latest work:`
      ]} />

      <div className='projects-container'>
        <ProjectCard 
          title='Raspored vlakova Hrvatske'
          text={`Mobile app design for all train travelers in Croatia.`}
          imgUri={require('../../assets/hz-image.png')} 
          backgroundColor={'#0500FF'}
          navigateTo={'/project/raspored vlakova hrvatske'}
          imageLeft={windowWidth <= 991 ? true : true}
        />

        <ProjectCard 
          title='Snap Shop'
          text={`User Experience and UI design for a mobile shopping app, focusing on personalized recommendations and seamless navigation. Designed for a modern retail experience.`}
          imgUri={require('../../assets/snap-shop-image.png')} 
          textColor={'#000000'}
          backgroundColor={'#F7E08B'}
          navigateTo={'/project/snapshop'}
          imageLeft={windowWidth <= 991 ? true : false}
        />
        
        <ProjectCard 
          title='Wedding Camera'
          text='User Experience and UI design for a wedding photo-sharing app, focusing on guest engagement and seamless photo uploads.'
          imgUri={require('../../assets/weddingcamera-image.png')} 
          imageLeft={windowWidth <= 991 ? true : true}
          textColor='#000000'
          backgroundColor='#FAF8F4'
          navigateTo={'/project/wedding camera'}
          />

        <ProjectCard 
          title='SnackFlix'
          text='User Experience and User Interface Design for SnackFlix to Revolutionize Movie Snack Delivery.'
          imgUri={require('../../assets/snackflix-image.png')} 
          imageLeft={windowWidth <= 991 ? true : false}
          backgroundColor='#D98200'
          navigateTo={'/project/snackflix'}
        />

        <ProjectCard 
          title='MCODE'
          text={`User Experience, Web app design and UI for small startup currently operating in Croatia. \n Coming soon ..`}
          imgUri={require('../../assets/mcode-image.png')} 
          backgroundColor='#160057' 
          imageLeft={windowWidth <= 991 ? true : true}
          // navigateTo={'/project/mcode'}
          navigateTo={'https://mcode.hr/'}
          btnText='Visit website'
          />
          
      </div>
      
      <QuoteCards />

      <GetInTouch />
    </div>
  )
}

export default Work