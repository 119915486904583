import React from "react";
import {
  createBrowserRouter,
} from "react-router-dom";
import About from "../pages/about";
import Work from "../pages/work";
import Contact from "../pages/contact";
import App from "../App";
import Project from "../pages/project";

export const router = createBrowserRouter([
  {
    
    path: "",
    element: <App />,
    children: [
      { path: "/", element: <About /> }, 
      {
        path: "/work",
        element: <Work />
      },
      {
        path: "/contact",
        element: <Contact />
      },
      {
        path: "/project/:id",
        element: <Project />
      },
    ],
  },

]);