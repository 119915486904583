import { IProjectsData } from "../interface";

export const projectsData :IProjectsData[] = [
  {
    title: "Raspored vlakova Hrvatske",
    desciption: "Designing this app allowed me to tackle user experience challenges, enhance my skills in wireframing and prototyping, and collaborate closely with developer. See how we improved travel efficiency, addressed user pain points, and created an intuitive interface that has already garnered 3,000 downloads on Google Play. Explore the journey of transforming the travel experience for thousands of commuters and tourists, and see the impact of our user-centered design approach.",
    sections: [
      // {
      //   type: "main-title",
      //   typeData: {
      //     title: 'Design a mobile app for all train travelers in Croatia',
      //     image: require('../assets/hz-project/hz-main.png')
      //   }
      // },
      {
        type: "title-image",
        typeData: {
          title: 'Design a mobile app for all train travelers in Croatia',
          image: require('../assets/hz-project/hz-main.png')
        }        
      },
      {
        type: 'overlaping-cards',
        typeData: [
          {
            title: "About project",
            text: [ 
              `Raspored vlakova Hrvatske is an app intended for all travelers within Croatia. It offers efficiency in journey planning, convenient ticket purchasing, real-time updates and an enhanced user experience.`,
              "The goal was provide passengers with a simple and reliable way to track train schedules, purchase tickets, and receive information about any delays, ensuring that train travel is as comfortable and stress-free as possible."
            ]
          },
          {
            title: "My role and responsibilities",
            text: [
              'UX designer designing a Raspored vlakova Hrvatske app from conception to delivery.',
              "Conducting interviews, paper and digital wireframing, low and high-fidelity prototyping, conducting usability studies, accounting for accessibility and iterating on designs."
            ]
          },
        ]
      },
      {
        type: 'title-text',
        typeData: {
          title: 'Users',
          text: [
            'User research focused on understanding the needs and preferences of individuals who frequently travel by train.',
            'I conducted a combination of online surveys and in-person interviews with a diverse group of train travelers across different demographics, including age, occupation, and travel frequency. The research was carried out over a span of two months, involving over 100 participants.'
          ]
        }
      },
      {
        type: 'user-research',
        typeData: [
          {
            title: 'Limited real-time informations',
            text: 'Train travelers frequently face challenges in obtaining accurate and up-to-date information about train schedules, delays, and platform changes.'
          },
          {
            title: "Too much informations",
            text: 'The existing app contains too much irrelevant information that can confuse users.'
          },
          {
            title: 'Inconvenient ticket booking process',
            text: 'Traditional methods of purchasing train tickets, such as queuing at ticket counters or navigating complex booking websites, can be time-consuming and cumbersome.'
          }
        ]
      },
      {
        type: 'title-image',
        typeData: {
          title: 'User persona',
          image: require('../assets/hz-project/user-persona.png')
        }
      },
      {
        type: "title-text",
        typeData: {
          title: 'Digital wireframes',
          text: `Digital wireframes provided a structured visual blueprint that allowed me to experiment with different layouts, user interfaces, and features. It helped me quickly iterate and test various design ideas, ensuring the app's user experience was intuitive and efficient.`
        }
      }, 
      {
        type: 'image',
        typeData: {
          image: require('../assets/hz-project/digital-wireframe.png'),
          name: 'wireframe'
        }
      },
      {
        type: 'text-url',
        typeData: [
          {
            text: 'View the Raspored vlakova Hrvatske',
            url: 'https://www.figma.com/proto/Ugj4HY9ECJ19m3hHAguwup/hzpp-app?node-id=787-1518&t=xrhANyAGJa19TRL9-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=787%3A1518&show-proto-sidebar=1',
            urlTitle: 'Low-fidelity prototype'
          }
        ]
      }, 
      {
        type: 'title-text',
        typeData: {
          title: 'Mockups',
          text: "Digital wireframes helped me map out how different parts of the app would work together. They allowed me to sketch out things like train schedules, ticket buying, and updates about delays in a clear and organized way. By using digital wireframes, I could easily test out different ideas and make sure the app was easy to use for everyone."
        }
      },
      {
        type: 'image',
        typeData: {
          image: require('../assets/hz-project/mockup.png'),
          name: 'mockup'
        }
      },
      {
        type: 'text-url',
        typeData: [
          {
            text: 'View the Raspored vlakova Hrvatske',
            url: 'https://www.figma.com/proto/Ugj4HY9ECJ19m3hHAguwup/hzpp-app?node-id=708-1519&t=Cdhv3oAPT0xrh8SN-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=708%3A1519&show-proto-sidebar=1',
            urlTitle: 'High-fidelity prototype'
          },
          {
            text: 'Download Raspored vlakova Hrvatske on Android',
            url: 'https://play.google.com/store/apps/details?id=com.hzppapp&hl=hr',
            urlTitle: 'Play Store'

          },
          {
            text: 'Download Raspored vlakova Hrvatske on iOS',
            url: 'https://apps.apple.com/us/app/raspored-vlakova-hrvatske/id6504168712',
            urlTitle: 'App Store'

          }
        ]
      },
      {
        type: 'title-bullet-list',
        typeData: {
          title: 'What I learned:',
          bulletList: [
            "Emphasized the importance of understanding and addressing user needs through extensive research and usability testing.",
            "Gained insights into technical feasibility by working closely with developer.",
            "Learned the value of continuous feedback and refinement to enhance the final product.",
            "Adapted designs to meet technical limitations while maintaining usability.",
            "Enhanced ability to communicate design concepts and rationale effectively to team members."
          ]
        }
      },
      {
        type: 'title-bullet-list',
        typeData: {
          title: 'Impact:',
          bulletList: [
            "Achieved 3,000 downloads on the Google Play Store, demonstrating strong user interest and adoption.",
            "Facilitated easier and more efficient route planning for train travelers in Croatia.",
            "Provided real-time schedules and user-friendly navigation, enhancing the overall travel experience.",
            "Received favorable reviews, indicating high user satisfaction and app reliability.",
          ]
        }
      },
      {
        type: 'another-project',
        typeData: [
          {
            title: 'SnackFlix',
            description: 'User Experience and User Interface Design for SnackFlix to Revolutionize Movie Snack Delivery.',
            image: require('../assets/snackflix-image.png'),
            color: '#D98200',
            to: '/project/snackflix'

          },
          {
            title: 'MCODE',
            description: 'User Experience, Web app design and UI for small startup, currently operating in Croatia.',
            image: require('../assets/mcode-image.png'),
            color: '#160057',
            // to: '/project/mcode'
            to: 'https://mcode.hr/'
          }
        ]
      }
    ]
  },

  {
    title: "SnapShop",
    desciption: "By simplifying online shopping, SnapShop enhances user convenience, improves accessibility, and streamlines the purchasing process. This innovative platform helps businesses expand their customer reach, increase sales, and deliver a seamless shopping experience to consumers worldwide.",
    sections: [
      // {
      //   type: "main-title",
      //   typeData: {
      //     title: 'Design a mobile app for all train travelers in Croatia',
      //     image: require('../assets/hz-project/hz-main.png')
      //   }
      // },
      {
        type: "title-image",
        typeData: {
          title: 'Design a mobile app for all train travelers in Croatia',
          image: require('../assets/snapshop-project/snapshop-main.png')
        }        
      },
      {
        type: 'overlaping-cards',
        typeData: [
          {
            title: "About project",
            text: [ 
              `SnapShop is a modern e-commerce platform designed to simplify online shopping and enhance the user experience.`,
              "The goal of the project was to create an intuitive and visually appealing interface that allows users to easily browse, compare, and purchase products. The focus was on accessibility, optimizing the ordering process, and implementing features that encourage customer loyalty and boost sales."
            ]
          },
          {
            title: "My role and responsibilities",
            text: [
              'UX/UI designer.',
              `This included conducting user research to gain insights into shoppers' needs, preferences, and challenges.`,
              `I developed paper and digital wireframes to outline the platform's structure and user flow, followed by creating low- and high-fidelity prototypes for testing and refinement`,
              `I emphasized accessibility to ensure the platform was intuitive and easy to navigate for all users`,
              `User testing and usability studies provided valuable feedback, which I used to iteratively improve the design and deliver a seamless e-commerce experience.`
            ]
          },
        ]
      },
      {
        type: 'title-text',
        typeData: {
          title: 'Users',
          text: [
            'User research primarily focused on busy individuals and families who frequently shop online and value convenience, variety, and affordability.',
            'Initially, I assumed that these users prioritized fast checkout and product availability. However, after conducting research, I discovered that their needs extended beyond this. They emphasized the importance of personalized recommendations, flexible payment options, and seamless navigation to create a stress-free and enjoyable shopping experience.'
          ]
        }
      },
      {
        type: 'user-research',
        typeData: [
          {
            title: 'Complex Navigation',
            text: 'Users struggled with finding products quickly due to overwhelming categories and poorly structured navigation, leading to frustration and abandonment of shopping sessions.'
          },
          {
            title: "Limited Personalization",
            text: `Many users expressed dissatisfaction with the lack of tailored product recommendations, feeling that the platform didn't cater to their individual preferences and shopping habits.`
          },
          {
            title: 'Inconsistent Checkout Process',
            text: 'The checkout process on some platforms is cumbersome, with too many steps and unclear payment options, causing delays and discouraging users from completing their purchases.'
          }
        ]
      },
      {
        type: 'title-image',
        typeData: {
          title: 'User persona',
          image: require('../assets/snapshop-project/user-persona.png')
        }
      },
      {
        type: "title-text",
        typeData: {
          title: 'Digital wireframes',
          text: `Digital wireframes played a key role in structuring the layout and functionality of the SnapShop app. They allowed me to visually plan and organize features such as product browsing, filtering options, and a streamlined checkout process. By creating and testing multiple iterations, I was able to refine the user flow and optimize the design for a seamless shopping experience.`
        }
      }, 
      {
        type: 'image',
        typeData: {
          image: require('../assets/snapshop-project/digital-wireframe.png'),
          name: 'wireframe'
        }
      },
      {
        type: 'text-url',
        typeData: [
          {
            text: 'View the SnapShop',
            url: 'https://www.figma.com/design/9udet1aUz4MeJWKVoo3qFv/05.-Prototyping-Resource-(Copy)?node-id=29574-6327&p=f&t=TFuRvNiEP0o9rSBh-0',
            urlTitle: 'Low-fidelity prototype'
          }
        ]
      }, 
      {
        type: 'title-text',
        typeData: {
          title: 'Mockups',
          text: [
            "During the usability study, users indicated that they primarily used the search function when browsing categories and products rather than as an initial action upon opening the app. Placing the search bar in the bottom navigation made it easier to access during exploration, without requiring users to scroll back to the top.",
            'Conversely, the cart icon was moved to the top-right corner, a more familiar placement based on common e-commerce patterns. This change aligns with users’ expectations, and improving efficiency when accessing the cart.',
            'By optimizing the layout based on usability testing, the final design ensures a smoother and more intuitive browsing experience for users.'
          ]
        }
      },
      {
        type: 'image',
        typeData: {
          image: require('../assets/snapshop-project/mockup.png'),
          name: 'mockup'
        }
      },
      {
        type: 'image',
        typeData: {
          image: require('../assets/snapshop-project/mockup-2.png'),
          name: 'mockup'
        }
      },
      {
        type: 'text-url',
        typeData: [
          {
            text: 'View the SnapShop',
            url: 'https://www.figma.com/design/WundT7SWhlNBXO9Is6bf82/14.-Design-System-and-Compositions-(Copy)?node-id=26629-2887&t=zuBbKumxHoTQYyfw-0',
            urlTitle: 'High-fidelity prototype'
          },
        ]
      },
      {
        type: 'title-bullet-list',
        typeData: {
          title: 'What I learned:',
          bulletList: [
            "The Importance of Personalization:\nUsers expect personalized experiences and recommendations, which significantly improve engagement and satisfaction.",
            "Simplified Navigation is Key:\nClear and intuitive navigation leads to better user retention and a more efficient shopping experience.",
            "Streamlining the Checkout Process:\nA simplified, transparent, and fast checkout process is essential for reducing cart abandonment and improving conversion rates",
          ]
        }
      },
      {
        type: 'title-bullet-list',
        typeData: {
          title: 'Impact:',
          bulletList: [
            "Enhanced User Experience:\nThe design improvements focused on creating a more intuitive and enjoyable shopping journey, from browsing to checkout.",
            "Potential for Increased Engagement:\nWith personalized recommendations and simplified navigation, the platform would likely engage users more effectively, encouraging them to explore and purchase more products.",
            "Improved Conversion Potential:\nA streamlined checkout process and better navigation would likely reduce friction points, increasing the chances of higher conversion rates once implemented.",
          ]
        }
      },
      {
        type: 'another-project',
        typeData: [
          {
            title: 'SnackFlix',
            description: 'User Experience and User Interface Design for SnackFlix to Revolutionize Movie Snack Delivery.',
            image: require('../assets/snackflix-image.png'),
            color: '#D98200',
            to: '/project/snackflix'

          },
          {
            title: 'MCODE',
            description: 'User Experience, Web app design and UI for small startup, currently operating in Croatia.',
            image: require('../assets/mcode-image.png'),
            color: '#160057',
            // to: '/project/mcode'
            to: 'https://mcode.hr/'
          }
        ]
      }
    ]
  },

  {
    title: "Wedding Camera",
    desciption: "Wedding Camera is a unique app that allows wedding guests to capture and share memories through a one-time-use camera. By simplifying the photography process, it creates a collaborative experience, providing couples with a memorable collection of photos from their special day.",
    sections: [
      // {
      //   type: "main-title",
      //   typeData: {
      //     title: 'Design a mobile app for all train travelers in Croatia',
      //     image: require('../assets/hz-project/hz-main.png')
      //   }
      // },
      {
        type: "title-image",
        typeData: {
          title: 'Design a mobile app for all train travelers in Croatia',
          image: require('../assets/weddingcamera-project/weddingcamera-main.png')
        }        
      },
      {
        type: 'overlaping-cards',
        typeData: [
          {
            title: "About project",
            text: [ 
              `Wedding Camera is an innovative mobile app designed to transform the way wedding guests capture and share memories.`,
              "The goal of the project was to create a user-friendly and visually appealing platform that allows guests to take photos during the event and upload them to a shared gallery.",
              "The focus was on simplicity, engagement, and providing couples with a unique collection of moments from their special day, all while enhancing the guest experience and creating a lasting keepsake.",
            ]
          },
          {
            title: "My role and responsibilities",
            text: [
              'Owner of Wedding Camera.',
              `Concept and ideation:\nDeveloped the initial idea and defined the core functionalities of the app.`,
              `UI/UX design:\nCreated wireframes, mockups, and the overall user interface to ensure an intuitive and engaging experience.`,
              `Collaboration with developers:\nWorked closely with the development team to ensure seamless functionality and high-quality user experience.`,
              `Business development:\nManaged the strategic planning, marketing, and promotion of the SaaS product.`,
              `Ownership:\nOversaw the app's direction, vision, and continued development as the owner of Wedding Camera.`,
            ]
          },
        ]
      },
      {
        type: 'title-text',
        typeData: {
          title: 'Users',
          text: [
            'User research for Wedding Camera focused on couples planning their weddings and their guests, who value unique experiences and memorable moments. Initially, I assumed that users would primarily be interested in a simple photo-sharing platform.',
            'However, after conducting research, I found that users valued an easy-to-use interface, the ability to seamlessly capture and upload photos, and the importance of creating a collaborative experience. They also emphasized the desire for a simple, stress-free way to preserve their memories.'
          ]
        }
      },
      {
        type: 'user-research',
        typeData: [
          {
            title: 'Complex photo-sharing',
            text: 'Users found it difficult to collect and share wedding photos across multiple platforms.'
          },
          {
            title: "Low guest engagement",
            text: `Guests had no easy way to contribute their own photos or feel involved in creating memories.`
          },
          {
            title: 'Limited photo access',
            text: 'Couples struggled to gather all wedding memories in one place, risking missed moments.'
          }
        ]
      },
      {
        type: 'title-image',
        typeData: {
          title: 'User persona',
          image: require('../assets/weddingcamera-project/user-persona.png')
        }
      },
      {
        type: "title-text",
        typeData: {
          title: 'Digital wireframes',
          text: `Digital wireframes were essential in mapping out the layout and key functionalities of the Wedding Camera app. They helped me visualize and organize elements like photo-taking, gallery uploads, and guest interaction. By creating and refining different versions, I was able to improve the user journey and ensure the app was intuitive and easy to use for everyone involved.`
        }
      }, 
      {
        type: 'image',
        typeData: {
          image: require('../assets/weddingcamera-project/digital-wireframe.png'),
          name: 'wireframe'
        }
      },
      {
        type: 'title-text',
        typeData: {
          title: 'Mockups',
          text: [
            "During the usability study, users expressed a strong desire to upload photos from their personal galleries to the shared wedding album. They wanted a more personalized way to contribute to the overall collection. Based on this feedback, we added an image upload button, allowing guests to easily transfer their photos into the app’s shared gallery, enhancing the overall user experience and guest engagement.",
          ]
        }
      },
      {
        type: 'image',
        typeData: {
          image: require('../assets/weddingcamera-project/mockup.png'),
          name: 'mockup'
        }
      },
      {
        type: 'image',
        typeData: {
          image: require('../assets/weddingcamera-project/mockup-2.png'),
          name: 'mockup'
        }
      },
      {
        type: 'text-url',
        typeData: [
          {
            text: 'Visit Wedding Camera website',
            url: 'https://weddingcamera.app',
            urlTitle: 'Promotional website'
          },
          {
            text: 'Visit Wedding Camera on App Store',
            url: 'https://apps.apple.com/us/app/id6608982445',
            urlTitle: 'App Store'
          },
          {
            text: 'Visit Wedding Camera on Google Play',
            url: 'https://play.google.com/store/apps/details?id=com.mcode.weddingcam',
            urlTitle: 'Google Play'
          },
        ]
      },
      {
        type: 'title-bullet-list',
        typeData: {
          title: 'What I learned:',
          bulletList: [
            "Owning the app taught me how to balance design, functionality, and user needs.",
            "Managing the process from start to finish showed me the challenges of creating a product that solves real problems.",
            "User feedback and iterative design are essential for refining the app.",
            "I learned to prioritize features and make decisions that enhance user satisfaction.",
          ]
        }
      },
      {
        type: 'title-bullet-list',
        typeData: {
          title: 'Impact:',
          bulletList: [
            "Simplifies the process of collecting and sharing wedding photos.",
            "Boosts guest engagement by allowing them to contribute to the wedding memory collection.",
            "Provides couples with a collaborative album filled with meaningful moments from their guests.",
          ]
        }
      },
      {
        type: 'another-project',
        typeData: [
          {
            title: 'Raspored vlakova Hrvatske',
            description: 'Design a mobile app for all train travelers in Croatia.',
            image: require('../assets/hz-image.png'),
            color: '#0500FF',
            to: '/project/raspored vlakova hrvatske'
          },
          {
            title: 'MCODE',
            description: 'User Experience, Web app design and UI for small startup, currently operating in Croatia.',
            image: require('../assets/mcode-image.png'),
            color: '#160057',
            // to: '/project/mcode'
            to: 'https://mcode.hr/'
          }
        ]
      }
    ]
  },

  {
    title: "SnackFlix",
    desciption: "By streamlining snack orders, SnackFlix enhances user convenience, boosts cinema attendance, and elevates customer satisfaction. This innovative approach helps cinemas achieve their business objectives by increasing revenue and delivering a superior movie-watching experience to film enthusiasts everywhere.",
    sections: [
      {
        type: "main-title",
        typeData: {
          title: 'Design a mobile app for all train travelers in Croatia',
          image: require('../assets/snackflix-project/snackflix-main.png')
        }
      },
      {
        type: "title-image",
        typeData: {
          title: 'Design a mobile app for all train travelers in Croatia',
          image: require('../assets/snackflix-project/snackflix-main.png')
        }        
      },
      {
        type: 'overlaping-cards',
        typeData: [
          {
            title: "About project",
            text: [ 
              `Designed for busy moviegoers, SnackFlix allows users to effortlessly pre-order their favorite snacks and beverages from their seats or even before arriving at the theater. By eliminating the hassle of long queues and ensuring timely delivery to their seats, SnackFlix enhances the overall cinema experience.`            
            ]
          },
          {
            title: "My role and responsibilities",
            text: [
              'As the UX designer for SnackFlix, my role involved spearheading the entire design process from inception to launch. This encompassed conducting user interviews to understand moviegoers needs, preferences, and pain points.',
              "I crafted paper and digital wireframes to visualize the app's structure and flow, followed by developing low and high-fidelity prototypes for iterative testing and refinement.",
              'Throughout the design phase, I prioritized accessibility considerations to ensure all users could navigate the app seamlessly.',
              'Conducting usability studies allowed me to gather invaluable feedback, which guided continuous improvements to the design.'
            ]
          },
        ]
      },
      {
        type: 'title-text',
        typeData: {
          title: 'Users',
          text: [
            'User research primarily focused on busy workers who frequently attend movie theaters and face time constraints when it comes to waiting in long lines.',
            'Initially, I assumed that these users were primarily concerned with speed and convenience. However, after conducting research, I discovered that their needs extended beyond this, with a strong emphasis on personalization, movie-themed promotions, and seamless payment options.'
          ]
        }
      },
      {
        type: 'user-research',
        typeData: [
          {
            title: 'Time',
            text: 'Working people and families with children do not have time to wait in long lines.'
          },
          {
            title: "Accessibility",
            text: 'Platforms for ordering food are not equipped with assistive technologies.'
          },
          {
            title: 'Nutritional information',
            text: 'Many food ordering apps do not provide enough information about the food (allergens, calories, proteins, etc.)'
          }
        ]
      },
      {
        type: 'title-image',
        typeData: {
          title: 'User persona',
          image: require('../assets/snackflix-project/user-persona.png')
        }
      },
      {
        type: "title-text",
        typeData: {
          title: 'Digital wireframes',
          text: "Digital wireframes were essential in mapping out how various components of SnackFlix would interact. They enabled me to clearly sketch out features like snack selection, order tracking, and seat delivery options in an organized manner. By utilizing digital wireframes, I could efficiently test different concepts and ensure the app's usability for all users."
        }
      }, 
      {
        type: 'image',
        typeData: {
          image: require('../assets/snackflix-project/digital-wireframe.png'),
          name: 'wireframe'
        }
      },
      {
        type: 'text-url',
        typeData: [
          {
            text: 'View the Snackflix',
            url: 'https://www.figma.com/proto/HtJlmfQx74jPazmCor0pBL/Untitled?node-id=146-1240&t=J4Nx2dvTwbZR9Ar8-1&scaling=min-zoom&content-scaling=fixed&page-id=146%3A34&starting-point-node-id=146%3A35',
            urlTitle: 'Low-fidelity prototype'
          }
        ]
      }, 
      {
        type: 'title-text',
        typeData: {
          title: 'Mockups',
          text: [
            `During the first usability study, users expressed dissatisfaction that there was only an option for delivering snacks to the seat. Taking that into account, I created the “pick up at the counter” button so that users have more options at their disposal.`,
            `Incorporating a "Deliver to Seat" button is immensely beneficial as it saves users valuable time and enhancing their overall satisfaction. This feature caters specifically to target audience of busy moviegoers who don't have time to wait in lines.`

          ]
        }
      },
      {
        type: 'image',
        typeData: {
          image: require('../assets/snackflix-project/testing-result-image.png'),
          name: 'mockup'
        }
      },
      {
        type: 'image',
        typeData: {
          image: require('../assets/snackflix-project/mockup-2.png'),
          name: 'mockup'
        }
      },
      {
        type: 'text-url',
        typeData: [
          {
            text: 'View the Snackflix',
            url: 'https://www.figma.com/proto/HtJlmfQx74jPazmCor0pBL/Untitled?node-id=204-310&t=Y6qgpn0m5Mmsj5Rf-1&scaling=min-zoom&content-scaling=fixed&page-id=204%3A5&starting-point-node-id=204%3A150',
            urlTitle: 'High-fidelity prototype'
          }
        ]
      },
      {
        type: 'title-bullet-list',
        typeData: {
          title: 'What I learned:',
          bulletList: [
            "Gained hands-on experience in the complete UX design process from conception to delivery.",
            "Learned the importance of user research through interviews to identify key needs and pain points.",
            "Developed skills in creating paper and digital wireframes to visualize app structure and functionality.",
            "Enhanced proficiency in building low and high-fidelity prototypes for iterative testing and refinement.",
            "Understood the critical role of accessibility in designing inclusive user experiences.",
            "Conducted usability studies to gather feedback and make data-driven design decisions.",
            "Improved ability to work independently, managing all aspects of the UX design process for my first project."
          ]
        }
      },
      {
        type: 'title-bullet-list',
        typeData: {
          title: 'Impact:',
          bulletList: [
            "Reduced wait times and eliminated the need to stand in long queues.",
            "Enhanced overall cinema experience with timely seat delivery of snacks.",
            "Boosted cinema attendance by offering a unique and attractive service.",
            "Helped cinemas achieve their business objectives by enhancing customer service and operational efficiency.",
          ]
        }
      },
      {
        type: 'another-project',
        typeData: [
          {
            title: 'Raspored vlakova Hrvatske',
            description: 'Design a mobile app for all train travelers in Croatia.',
            image: require('../assets/hz-image.png'),
            color: 'rgb(5, 0, 255)',
            to: '/project/raspored vlakova hrvatske'
          },
          {
            title: 'MCODE',
            description: 'User Experience, Web app design and UI for small startup, currently operating in Croatia.',
            image: require('../assets/mcode-image.png'),
            color: '#160057',
            // to: '/project/mcode'
            to: 'https://mcode.hr/'
            
          }
        ]
      }
    ]
  },

  {
    title: "MCODE",
    desciption: "By streamlining snack orders, SnackFlix enhances user convenience, boosts cinema attendance, and elevates customer satisfaction. This innovative approach helps cinemas achieve their business objectives by increasing revenue and delivering a superior movie-watching experience to film enthusiasts everywhere.",
    sections: [
      {
        type: "main-title",
        typeData: {
          title: 'Design a mobile app for all train travelers in Croatia',
          image: require('../assets/mcode-project/mcode-main.png')
        }
      },
      {
        type: "title-image",
        typeData: {
          title: 'Design a mobile app for all train travelers in Croatia',
          image: require('../assets/mcode-project/mcode-main.png')
        }        
      },
      {
        type: 'overlaping-cards',
        typeData: [
          {
            title: "About project",
            text: [ 
              `Designed for busy moviegoers, SnackFlix allows users to effortlessly pre-order their favorite snacks and beverages from their seats or even before arriving at the theater. By eliminating the hassle of long queues and ensuring timely delivery to their seats, SnackFlix enhances the overall cinema experience.`            
            ]
          },
          {
            title: "My role and responsibilities",
            text: [
              'As the UX designer for SnackFlix, my role involved spearheading the entire design process from inception to launch. This encompassed conducting user interviews to understand moviegoers needs, preferences, and pain points.',
              "I crafted paper and digital wireframes to visualize the app's structure and flow, followed by developing low and high-fidelity prototypes for iterative testing and refinement.",
              'Throughout the design phase, I prioritized accessibility considerations to ensure all users could navigate the app seamlessly.',
              'Conducting usability studies allowed me to gather invaluable feedback, which guided continuous improvements to the design.'
            ]
          },
        ]
      },
      {
        type: 'title-text',
        typeData: {
          title: 'Users',
          text: [
            'User research primarily focused on busy workers who frequently attend movie theaters and face time constraints when it comes to waiting in long lines.',
            'Initially, I assumed that these users were primarily concerned with speed and convenience. However, after conducting research, I discovered that their needs extended beyond this, with a strong emphasis on personalization, movie-themed promotions, and seamless payment options.'
          ]
        }
      },
      {
        type: 'user-research',
        typeData: [
          {
            title: 'Time',
            text: 'Working people and families with children do not have time to wait in long lines.'
          },
          {
            title: "Accessibility",
            text: 'Platforms for ordering food are not equipped with assistive technologies.'
          },
          {
            title: 'Nutritional information',
            text: 'Many food ordering apps do not provide enough information about the food (allergens, calories, proteins, etc.)'
          }
        ]
      },
      {
        type: 'title-image',
        typeData: {
          title: 'User persona',
          image: require('../assets/snackflix-project/user-persona.png')
        }
      },
      {
        type: "title-text",
        typeData: {
          title: 'Digital wireframes',
          text: "Digital wireframes were essential in mapping out how various components of SnackFlix would interact. They enabled me to clearly sketch out features like snack selection, order tracking, and seat delivery options in an organized manner. By utilizing digital wireframes, I could efficiently test different concepts and ensure the app's usability for all users."
        }
      }, 
      {
        type: 'image',
        typeData: {
          image: require('../assets/snackflix-project/digital-wireframe.png'),
          name: 'wireframe'
        }
      },
      {
        type: 'text-url',
        typeData: [
          {
            text: 'View the Raspored vlakova Hrvatske',
            url: 'https://mcode.hr',
            urlTitle: 'Low-fidelity prototype'
          }
        ]
      }, 
      {
        type: 'title-text',
        typeData: {
          title: 'Mockups',
          text: [
            `During the first usability study, users expressed dissatisfaction that there was only an option for delivering snacks to the seat. Taking that into account, I created the “pick up at the counter” button so that users have more options at their disposal.`,
            `Incorporating a "Deliver to Seat" button is immensely beneficial as it saves users valuable time and enhancing their overall satisfaction. This feature caters specifically to target audience of busy moviegoers who don't have time to wait in lines.`

          ]
        }
      },
      {
        type: 'image',
        typeData: {
          image: require('../assets/mcode-project/mcode-mockups.png'),
          name: 'mockup'
        }
      },
      {
        type: 'image',
        typeData: {
          image: require('../assets/snackflix-project/mockup-2.png'),
          name: 'mockup'
        }
      },
      {
        type: 'text-url',
        typeData: [
          {
            text: 'View the Snackflix',
            url: 'https://mcode.hr',
            urlTitle: 'High-fidelity prototype'
          }
        ]
      },
      {
        type: 'title-bullet-list',
        typeData: {
          title: 'What I learned:',
          bulletList: [
            "Gained hands-on experience in the complete UX design process from conception to delivery.",
            "Learned the importance of user research through interviews to identify key needs and pain points.",
            "Developed skills in creating paper and digital wireframes to visualize app structure and functionality.",
            "Enhanced proficiency in building low and high-fidelity prototypes for iterative testing and refinement.",
            "Understood the critical role of accessibility in designing inclusive user experiences.",
            "Conducted usability studies to gather feedback and make data-driven design decisions.",
            "Improved ability to work independently, managing all aspects of the UX design process for my first project."
          ]
        }
      },
      {
        type: 'title-bullet-list',
        typeData: {
          title: 'Impact:',
          bulletList: [
            "Reduced wait times and eliminated the need to stand in long queues.",
            "Enhanced overall cinema experience with timely seat delivery of snacks.",
            "Boosted cinema attendance by offering a unique and attractive service.",
            "Helped cinemas achieve their business objectives by enhancing customer service and operational efficiency.",
          ]
        }
      },
      {
        type: 'another-project',
        typeData: [
          {
            title: 'Raspored vlakova Hrvatske',
            description: 'Design a mobile app for all train travelers in Croatia.',
            image: require('../assets/snackflix-image.png'),
            color: '#D98200',
            to: '/project/snackflix'
          },
          {
            title: 'MCODE',
            description: 'User Experience, Web app design and UI for small startup, currently operating in Croatia.',
            image: require('../assets/snackflix-image.png'),
            color: '#160057',
            to: '/project/mcode'
          }
        ]
      }
    ]
  }
]