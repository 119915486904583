import React from 'react';
import './index.css'
import Button from '../../molecule/button';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../../hooks/scrollTo';

interface Props {
  title: string;
  text: string;
  imageLeft?:boolean
  imgUri: string;
  backgroundColor: string
  navigateTo?: string,
  btnText?: string,
  textColor?: string
}

const ProjectCard : React.FC<Props> = ({
  imageLeft = true, 
  imgUri,
  backgroundColor,
  title, 
  text,
  navigateTo= "",
  btnText = 'See more',
  textColor
}) => { 
  

  if(!imageLeft) {
    return (
      <div className='project-card-container'>
        <div className='project-card-text-container' style={{backgroundColor}}>
          <h2 style={textColor ? {color: textColor} : {}}>
            {title}
          </h2>
          <p style={textColor ? {color: textColor} : {}}>
            {text}
          </p>
  
          <div>
            <Link to={navigateTo} onClick={() => scrollToTop('instant' as ScrollBehavior)}>
              <Button>
                <p>{btnText}</p>
              </Button>
            </Link>
          </div>
        </div>
        <div className='project-card-image-container'>
          <img src={imgUri} alt='project'/>
        </div>
      </div>
    )
  }

  return (
    <div className='project-card-container'>
      <div className='project-card-image-container'>
        <img src={imgUri} alt='project'/>
      </div>
      <div className='project-card-text-container' style={{backgroundColor}}>
        <h2 style={textColor ? {color: textColor} : {}}>
          {title}
        </h2>
        <p style={textColor ? {color: textColor} : {}}>
          {text}
        </p>

        <div>
          <Link to={navigateTo} onClick={() => scrollToTop('instant' as ScrollBehavior)}>
            <Button>
              <p>{btnText}</p>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ProjectCard